import React from "react";
import { FaInfoCircle } from "react-icons/fa"; // Using react-icons for the info icon

const ContentComponent = ({ htmlContent }) => {
  // Define styles for the specific sections
  const styles = {
    content: {
      color: "blue",
      filter: "blur(1px)",
    },
    notes: {
      backgroundColor: "red",
      color: "white",
      padding: "20px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    summary: {
      backgroundColor: "#F5F5F7",
      color: "#3D3C6E",
      padding: "30px",
      borderRadius: "8px",
      fontSize: "18px",
      fontWeight: 500,
      marginTop:"50px"
    },
  };

  // Replace #content and #notes with styled elements
  const styledHtmlContent = htmlContent
    ?.replace(/#content/g, '<span style="color: #333333">')
    ?.replace(
      /#notes/g,
      `<div style="background-color: #ED1C240D; color: #333333; padding: 20px; border-radius: 12px; display: flex; align-items: center; gap: 8px;"><span style="font-size: 18px; font-weight: bolder;"></span>`
    )
    ?.replace(/<\/p>/g, "</div></p>")
    ?.replace(
      /#summary/g,
      `<div style="background-color: ${styles.summary.backgroundColor}; color: ${styles.summary.color}; padding: ${styles.summary.padding}; border-radius: ${styles.summary.borderRadius}; font-size: ${styles.summary.fontSize}; font-weight: ${styles.summary.fontWeight}; margin-top:${styles.summary.marginTop}">`
    );

  return <div dangerouslySetInnerHTML={{ __html: styledHtmlContent }} />;
};

export default ContentComponent;
