import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Space } from "antd";
import logo from "../../assets/Logo.png";
import { useSelector } from "react-redux";
import "./NavigationBar.scss";

const NavigationBar = () => {
  const [activeLink, setActiveLink] = useState("");

  const { cart, loading, error } = useSelector((state) => state.cartReducer);

  const navigate = useNavigate();
  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };
  const handleNaviagte = (key) => {
    if (key === "/") {
      window.location.href = `https://nucleargauges.com${key}`;
    }else{

      navigate(key);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("myValue");
    navigate("/login");
  };

  return (
    <Navbar expand="lg" className="navbar">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="150"
            className="d-inline-block align-top"
            alt="Nuclear Gauges USA Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link
              className={activeLink === "home" ? "active" : ""}
              onClick={() => {
                handleLinkClick("home");
                handleNaviagte("/");
              }}
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={activeLink === "dashboard" ? "active" : ""}
              onClick={() => {
                handleLinkClick("dashboard");
                handleNaviagte("/dashboard");
              }}
            >
              Dashboard
            </Nav.Link>
            <Nav.Link
              className={activeLink === "shop" ? "active" : ""}
              onClick={() => {
                handleLinkClick("shop");
                handleNaviagte("/courses");
              }}
            >
              Shop
            </Nav.Link>
            <Nav.Link
              className={activeLink === "contact" ? "active" : ""}
              onClick={() => {
                handleLinkClick("contact");
                handleNaviagte("/contactus");
              }}
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              className={activeLink === "shopingcart" ? "active" : ""}
              onClick={() => {
                handleNaviagte("/shopingcart");
              }}
            >
              <Space>
                <Badge count={error ? null : cart?.cartItems?.length} showZero>
                  <FaShoppingCart size={20} />
                </Badge>
              </Space>
            </Nav.Link>
            <NavDropdown title="Account" id="basic-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                to="/profile"
                className={activeLink === "profile" ? "active" : ""}
                onClick={() => handleLinkClick("profile")}
              >
                Profile
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;