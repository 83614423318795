import React from "react";
import { Card, Button, Skeleton, Progress } from "antd";
import "./VideoCard.scss";
import { IMAGE_URL } from "../../api's/Host";
import { Link, useNavigate } from "react-router-dom";
import nodataavailable from "../../assets/nodataavailable.jpg";
const VideoCard = ({ cardData, loading }) => {
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="cards">
        {[...Array(6)].map((_, index) => (
          <Skeleton active key={index} className="card-skeleton" />
        ))}
      </div>
    );
  }

  if (!Array.isArray(cardData) || cardData.length === 0) {
    return (
      <div className="no-data">
        <img src={nodataavailable} alt="No data available" />
        <p>No data available</p>
      </div>
    );
  }
  const progress = 25;
  return (
    <div className="cards">
      {cardData.map((item, index) => (
        <div key={index}>
          <Card
            hoverable
            className="card"
            cover={
              <img
                src={`${IMAGE_URL}/${item.image}`}
                alt="video"
                crossOrigin="anonymous"
              />
            }
          >
            <Link to={item.link} className="textlink">
              <h6 className="title">{item.title}</h6>
              <div>
                {item.type === "test" ? (
                  <div>
                    <span>{item.questions} Questions | </span>
                    <span>{item.minutes}</span>
                  </div>
                ) : (
                  <>
                    <div className="details">
                      <span>{item.lectures} Lectures</span>
                      <span>⭐ {item.rating}</span>
                    </div>
                    {item.dashboard && (
                      <div className="courseProgress">
                        <Progress percent={item.progress} showInfo={false} />
                        <p>{item.progress}% Completed</p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <h6 className="price">{item.price}</h6>
            </Link>

            <Button
              type="primary"
              className="button"
              onClick={() => {
                if (item.is_purchased !== undefined && item.is_purchased) {
                  navigate(item.navigateLink);
                } else {
                  item.handleClick(
                    item.course_id || item.test_id,
                    item.type,
                    item.navigateLink
                  );
                }
              }}
            >
              {item.button_status}
            </Button>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default VideoCard;
