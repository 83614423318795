import React, { useEffect, useMemo, useState } from "react";
import { Card, Rate, Input, message } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";
import Reviews from "../Reviews/Reviews";
import { IMAGE_URL } from "../../api's/Host";
import { useNavigate, useParams } from "react-router";
import "./CourseDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { getReviewAndRatings } from "../../redux/reviweAndRatinSlice";
import { GetCourseById, PostAddToCart, PostReviewandRatingsApi } from "../../api's/Api";

const { Meta } = Card;
const { TextArea } = Input;

const CourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState(null);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isRatingProvided, setIsRatingProvided] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
const navigate = useNavigate()
  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");
  const { reviews, loading, error } = useSelector(
    (state) => state.reviewRatingReducer
  );

  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id,
    }),
    [token, role_id]
  );

  useEffect(() => {
    GetCourseById(id)
      .then((res) => {
        if (res?.data?.success) {
          setCourseDetails(res?.data?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [id]);

  useEffect(() => {
    const formData = { course_id: id };

    dispatch(getReviewAndRatings({ formData, headers }));
  }, [id, headers, dispatch]);

  useEffect(() => {
    if (reviews?.data) {
      setIsRatingProvided(
        reviews?.data?.some((item) => item?.user_rating_provided)
      );
    }
  }, [reviews]);

  const handlePostReview = () => {
    if (rating === 0 || reviewText.trim() === "") {
      setValidationMessage("Please provide both rating and review text.");
      return;
    }
    setValidationMessage("");
    const formData = {
      course_id: id,
      rating: rating,
      review: reviewText,
    };
    PostReviewandRatingsApi(formData, headers)
      .then((res) => {
        if (res?.data && res?.data?.success) {
          message.success(res?.data?.message);
          setRating(0);
          setReviewText("");
          dispatch(
            getReviewAndRatings({ formData: { course_id: id }, headers })
          ); // Refresh reviews
        } else {
          message.error("Failed to post review. Please try again.");
        }
      })
      .catch((e) => {
        message.error("Error posting review. Please try again.");
        console.log("error", e);
      });
  };

  const handleAddToCart = () => {
    const formData = { course_id: id };
    PostAddToCart(formData, headers)
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message || "Course added to cart successfully!");
          setTimeout(()=>{
            navigate("/shopingcart")
          },2000)
        } else {
          message.error(res?.data?.message || "Failed to add course to cart.");
        }
      })
      .catch((error) => {
        console.log("error", error);
        message.error("An error occurred while adding the course to the cart.");
      });
  };


  return (
    <div className="course-details">
      <div className="course-container">
        <div className="course-header">
          <Container className="container">
            <div className="course-description">
              <h2>{courseDetails?.name}</h2>
              <p>{courseDetails?.description}</p>
            </div>
            <div className="course-image">
              <img
                src={`${IMAGE_URL}/${courseDetails?.course_attachments[0]?.file_name}`}
                alt={courseDetails?.course_attachments[0]?.title}
                crossOrigin="anonymous"
              />
            </div>
          </Container>
        </div>

        <div className="tests-included">
          <Container className="included-container">
            <h3>Tests Included</h3>
            <div className="test-cards">
              {courseDetails?.course_test?.map((test, index) => (
                <Card
                  key={index}
                  className="test-card"
                  cover={
                    <img
                      alt="example"
                      src={`${IMAGE_URL}/${test?.test_attachments[0]?.file_name}`}
                      crossOrigin="anonymous"
                      width="250px"
                    />
                  }
                >
                  <Meta
                    title={test?.name}
                    description={
                      <>
                        <p>
                          {test?.question_count} Questions | {test?.duration}
                        </p>
                        <CustomButton
                          text="Included"
                          bgColor="#ED1C2414"
                          color="#ED1C24"
                          width="100%"
                          padding="5px"
                        />
                      </>
                    }
                  />
                </Card>
              ))}
              <div className="amount-card">
                <p>Total Amount</p>
                <div className="total-amount">${courseDetails?.price}</div>
                <CustomButton text="Add To Cart" width="100%" onClick={handleAddToCart}/>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Container>
        <Row>
          <Col xs lg="9">
            <Reviews
              reviews={reviews}
              loading={loading}
              error={error}
              course_id={id}
              setIsRatingProvided={setIsRatingProvided}
            />

            {isRatingProvided === false ? (
              <div className="add-review">
                <h3>Add Your Review</h3>
                <Rate
                  allowHalf
                  value={rating}
                  onChange={(value) => setRating(value)}
                  className="ratings"
                />
                <TextArea
                  rows={4}
                  className="textArea"
                  placeholder="Write Here"
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                />
                {validationMessage && (
                  <div className="validation-message">{validationMessage}</div>
                )}
                <CustomButton
                  text="Post Review"
                  bgColor="#ED1C24"
                  color="white"
                  onClick={handlePostReview}
                />
              </div>
            ) : null}
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
};

export default CourseDetails;
