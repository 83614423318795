import React from "react";
import { Modal, Table } from "antd";
import CustomButton from "../CustomButton/CustomButton";
import "./CourseOngoing.scss";

const Result = ({ isVisible, onClose, quizResults, questions, answers, restartQuiz,handleContinue }) => {

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Your Answer",
      dataIndex: "yourAnswer",
      key: "yourAnswer",
    },
    {
      title: "Correct Answer",
      dataIndex: "correctAnswer",
      key: "correctAnswer",
    },
  ];

  const dataSource = questions.map((question, index) => ({
    key: question.id,
    question: `Q${index + 1}: ${question.question}`,
    yourAnswer: answers[`q${index + 1}`]
      ? question.quiz_answers.find(
          (answer) => answer.id === parseInt(answers[`q${index + 1}`])
        )?.option
      : "No answer selected",
    correctAnswer: question.quiz_answers.find((answer) => answer.correct)
      ?.option,
  }));

  return (
    <Modal
      title="Scoreboard"
      visible={isVisible}
      onCancel={onClose}
      style={{ width: "700px" }}
      footer={[
        <CustomButton key="restart" text="Restart Quiz" onClick={restartQuiz} />,
        <span style={{ margin: "0 8px" }} />, 
        <CustomButton key="done" text="Done" onClick={handleContinue} />,
      ]}
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
      />
    </Modal>
  );
};


export default Result;
