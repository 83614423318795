import React, { useEffect, useMemo, useState } from "react";
import { Card, Row, Col, Button, message } from "antd";
import { DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";
import { IMAGE_URL } from "../../api's/Host";
import { DeleteCartItems } from "../../api's/Api";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems } from "../../redux/addToCartSlice";
import { useNavigate } from "react-router";
import Loader from "../Loader/Loader";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import "./ShoppingCart.scss";
import SquarePaymentForm from "../SquarePaymentForm/SquarePaymentForm";

const ShoppingCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [isCheckout, setIsCheckout] = useState(false);

  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");

  const { cart, loading, error } = useSelector((state) => state.cartReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id,
    }),
    [token, role_id]
  );

  useEffect(() => {
    dispatch(getCartItems(headers));
  }, [dispatch, headers]);

  useEffect(() => {
    if (cart?.cartItems) {
      setCartItems(cart.cartItems);
    }
  }, [cart]);

  const cartItemCount = cartItems.length || 0;

  const handleDeleteCartItem = (id, type) => {
    let formData;
    if (type === "course") {
      formData = { course_id: id };
    } else if (type === "test") {
      formData = { test_id: id };
    }
    DeleteCartItems(formData, headers)
      .then((res) => {
        if (res.data.success) {
          dispatch(getCartItems(headers));
          message.success("Item removed from cart.");
        } else {
          message.error("Failed to remove item from cart.");
        }
      })
      .catch((e) => {
        console.log("error", e);
        message.error("An error occurred while removing item from cart.");
      });
  };

  const getImageUrl = (attachments) => {
    if (attachments && attachments.length > 0) {
      const { file_name } = attachments[0];
      return `${IMAGE_URL}/${file_name}`;
    }
    return "https://via.placeholder.com/50";
  };

  const handleCheckout = () => {
    setIsCheckout(true);
  };

  const handlePaymentCompleted = (data) => {
    message.success("Payment successful!");
    // Redirect the user or clear the cart here
    setIsCheckout(false);
    setTimeout(()=>{
      navigate("/dashboard");

    },2000)

  };

  const handleCancelPayment = () => {
    setIsCheckout(false);
  };

  return (
    <div className="shopping-cart">
      <Container>
        {loading ? (
          <div className="loading">
            <Loader />
          </div>
        ) : (
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Card title="Shopping Cart" bordered={false}>
                <div className="cart-summary">
                  <p>{error ? 0 : cartItemCount} Courses in Cart</p>
                </div>
                {error || cartItems.length === 0 ? (
                  <NoDataAvailable message="No Cart Items Available" />
                ) : (
                  cartItems.map((item, index) => (
                    <div key={index} className="course-item">
                      <img
                        className="cart-image"
                        crossOrigin="anonymous"
                        src={
                          item.cart_course
                            ? getImageUrl(item.cart_course.course_attachments)
                            : getImageUrl(item.cart_test.test_attachments)
                        }
                        alt="nuclear gauges"
                      />
                      <div className="course-details">
                        <h3>
                          {item.cart_course
                            ? item.cart_course.name
                            : item.cart_test.name}
                        </h3>
                        <p>
                          {item.cart_course
                            ? `${item.cart_course.lecture_count} Lectures`
                            : `${item.cart_test.question_count} Questions`}
                        </p>
                      </div>
                      <div className="course-actions">
                        <span className="course-price">
                          {item.cart_test && item.cart_test.price > 0 && (
                            <span
                              style={{
                                textDecoration: "line-through",
                                marginRight: "8px",
                              }}
                            >
                              ${item.cart_test.price}
                            </span>
                          )}
                          ${item.total_price}
                        </span>
                        {item.free_with_course_id === null &&
                        item.free_with_test_id === null ? (
                          <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() =>
                              handleDeleteCartItem(
                                item.course_id || item.test_id,
                                item.course_id ? "course" : "test"
                              )
                            }
                          />
                        ) : null}
                      </div>
                    </div>
                  ))
                )}
              </Card>
            </Col>
            <Col span={6}>
              {cartItems.length === 0 ? null : (
                <>
                  <Card className="total-amount-card">
                    <h4>Total Amount</h4>
                    <h3>${error ? 0 : cart?.totalPrice || 0}</h3>
                    <CustomButton
                      text="Checkout"
                      width="100%"
                      onClick={handleCheckout}
                    />
                  </Card>
                </>
              )}
            </Col>
          </Row>
        )}
        {isCheckout && (
          <div className="payment-form-container">
            <button className="cancel-button" onClick={handleCancelPayment}>
              <CloseOutlined />
            </button>
            <SquarePaymentForm onPaymentCompleted={handlePaymentCompleted} />
          </div>
        )}
      </Container>
    </div>
  );
};

export default ShoppingCart;
